import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from 'vee-validate'
import { required, max, email, min, confirmed, alpha_dash, alpha_spaces, numeric, digits, length, min_value } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})
extend('alpha_spaces', alpha_spaces)
extend('alpha_dash', alpha_dash)
extend('max', max)
extend('email', email)
extend('min', min)
extend('confirmed', confirmed)
extend('length', length)
extend('digits', digits)
extend('numeric', numeric)
extend('min_value', {
  ...min_value,
  message: 'Minimum age required is 21'
})

extend('min_value_price', {
  ...min_value,
  message: 'Minimum price required is 1'
})
extend('positive', value => {
    if (value >= 0) {
      return true;
    }
    return 'This field must be a positive number';
  });
  extend('lat', value => {
    let regEx = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid latitude';
  });
  extend('long', value => {
    let regEx = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid longitude';
  });
   extend('phoneNumberOrEmail', value => {
    let regExPhone = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/
    let regExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (regExPhone.test(value) || regExEmail.test(value) ) {
      return true;
    }
    return 'Please enter valid email or phone number';
  });
  extend('phoneNumber', value => {
    let regEx = /^(44\s?7\d{3}|\(?0?7\d{3}\)?)\s?\d{3}\s?\d{3}$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid UK phone number';
  });

  extend('postalCode', value => {
    let regEx = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid UK postal code';
  });

  extend('url', value => {
    let regEx = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid url';
  });

  extend('openCloseTime', {
    params: ['target'],
    validate(value, { target }) {
      return value !== target;
    },
    message: 'Open and close time cannot be same'
  });

  extend('fbUrl', value => {
    let regEx = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid facebook URL';
  });
  extend('twitter', value => {
    let regEx = /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9(\.\?)?]/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid twitter URL';
  });
  extend('linkedIn', value => {
    let regEx = /^(https?:\/\/)?(www\.)?linkedin.com\/[a-zA-Z0-9(\.\?)?]/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid linkedIn URL';
  });
  extend('pinterest', value => {
    let regEx =  /^(https?:\/\/)?(www\.)?pinterest.com\/[a-zA-Z0-9(\.\?)?]/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid pinterest URL';
  });
  extend('password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Password confirmation does not match'
  });

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver) 