
  export default {
    props: ['error'],
    layout: 'error', // you can set a custom layout for the  page
    head() {
    return {
      meta: [
        { hid: 'og:url',
        property: 'og:url',
        content: this.$config.siteUrl + this.$route.path
      },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://www.goa.app' + this.$route.path
        }
      ]
    }
  },
  }

