export const state = () => ({
    isLogIn: false,
    showSeoPackages: true,
    profile: null,
  })
  
  export const mutations = {
    login(state) {
      state.isLogIn = true;
    },
    profile(state,val) {
        state.profile = val;
      },
    islogingin(state,val) {
        if(val){
        state.isLogIn = true;
        }
        else{
        state.isLogIn = false;
        }
      },
    logout(state) {
        if(process.browser){window.localStorage.clear()}
        state.isLogIn = false;
        state.showSeoPackages = true;
      },
  }